import { Link } from "react-router-dom";
import "./CardStyle.css";

const CardVisits = ({ data, t }) => {
  function convertToThumbnailUrl(url) {
    // Extract the directory, filename, and extension
    const parts = url.split("/"); // Split the URL into segments
    const filename = parts.pop(); // Get the filename (e.g., "carthage.jpg")
    const [name, ext] = filename.split("."); // Split into name and extension

    // Insert "thumbnails" into the path just before the filename
    parts.push("thumbnails"); // Add "thumbnails" directory to the path
    const thumbnailPath = `${parts.join("/")}/${name}_thumbnail.${ext}`; // Construct the new URL

    return thumbnailPath;
  }

  return (
    <div className="slider-container-visits">
      <Link
        className="slide-content"
        to={`/site/${data.details[0].site_link}/${
          data.isPack ? "pack" : "location"
        }/${data.link}/${t("lang") || "en"}`}
      >
        <img
          className="slide-image"
          src={convertToThumbnailUrl(data.image)}
          alt={data.name || data.location_name}
        />
        <div className="label">
          <div className="label-background"></div>
          <div className="label-text">{data.name || data.location_name}</div>
        </div>
      </Link>
      <center>
        <Link
          to={`https://vworld.historiar.io/browse/${data.link}/${
            data.voucher
          }/${t("lang")}`}
        >
          <button className="btn btn-primary">{t("visit")}</button>
        </Link>
      </center>
    </div>
  );
};

export default CardVisits;
