import React from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.css";

function SearchBar({ t, sites, locations }) {
  const navigate = useNavigate();

  // Transform data and group locations by site
  const transformedData = sites
    .map((site) => {
      const groupedLocations = locations.filter(
        (location) => location.site_id === site.id
      );
      if (groupedLocations.length > 0) {
        return {
          // Use site name as the group label
          locations: groupedLocations.map((location) => ({
            group: site.name,
            title: location.location_name, // Location name as the label
            siteLink: site.link,
            subsiteLink: location.link, // Location-specific link
          })),
        };
      }
      return null;
    })
    .filter((group) => group !== null); // Remove empty groups

  const handleInputChange = (event, value) => {
    if (value && value.siteLink) {
      navigate(
        `/site/${value.siteLink}/location/${value.subsiteLink}/${t("lang")}`
      );
    }
  };

  return (
    <Autocomplete
      freeSolo
      onChange={handleInputChange}
      className="autocomplete"
      id="search-bar"
      options={transformedData.flatMap((data) => data.locations)} // Flatten grouped locations for options
      groupBy={(option) => option.group} // Group options by site name
      getOptionLabel={(option) => option.title} // Display location name in the dropdown
      renderInput={(params) => (
        <TextField
          {...params}
          className="searchBar"
          variant="filled"
          label={t("search") + "..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="end" className="searchIcon">
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchBar;
