import React from "react";
import { Link } from "react-router-dom";
import "./FormStyle.css";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import { useGoogleLogin, useGoogleOneTapLogin } from "@react-oauth/google";
import { authenticationService } from "../../services/auth";
import { jwtDecode } from "jwt-decode";

function LoginForm({ t }) {
  const handleGoogleLogin = async (response) => {
    try {
      let userData;

      // Check if response contains an access_token or id_token
      if (response.access_token) {
        // Fetch user info using access_token
        const userInfoResponse = await fetch(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        const userInfo = await userInfoResponse.json();

        userData = {
          email: userInfo.email,
          name: userInfo.name,
          provider: "google",
          provider_id: userInfo.id,
        };
      } else if (response.credential) {
        // Decode id_token to get user info
        const decodedToken = jwtDecode(response.credential);

        userData = {
          email: decodedToken.email,
          name: decodedToken.name,
          provider: "google",
          provider_id: decodedToken.sub,
        };
      } else {
        throw new Error("Invalid response from Google authentication.");
      }

      // Authenticate the user with your backend
      const result = await authenticationService(userData);
      if (result.ok) {
        window.location.assign("/"); // Redirect to the home page
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  };

  useGoogleOneTapLogin({
    onSuccess: async (tokenResponse) => {
      await handleGoogleLogin(tokenResponse);
    },
    onError: () => {
      console.log("One Tap Login Failed");
    },
  });

  // Google Login with Button
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      await handleGoogleLogin(tokenResponse);
    },
  });

  const loginWithFacebook = () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          // User logged in successfully, fetch their information
          window.FB.api(
            "/me",
            { fields: "id,name,email,picture" },
            async function (userInfo) {
              if (userInfo && userInfo.email && userInfo.name) {
                const userData = {
                  email: userInfo.email,
                  name: userInfo.name,
                  provider: "facebook",
                  provider_id: userInfo.id,
                };

                try {
                  const result = await authenticationService(userData);
                  if (result.ok) window.location.assign("/"); // Redirect to the home page
                } catch (error) {
                  console.error("Error during authentication:", error);
                }
              } else {
                console.error("User info is incomplete or missing.");
              }
            }
          );
        } else {
          // Log a message when the user cancels or closes the login window
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email,public_profile" } // Request email and profile permissions
    );
  };

  return (
    <div className="Formulaire">
      <div className="form login">
        <div
          className="form-content"
          style={{ direction: t("lang") == "ar" ? "rtl" : "ltr" }}
        >
          <center>
            <Link to="/">
              <img
                src="/logo-historiar-light.png"
                alt="logo"
                className="logo"
              />
            </Link>
          </center>
          <br />
          <header>{t("login_header")}</header>
          <br />
          <br />

          <FacebookLoginButton onClick={loginWithFacebook}>
            <span>{t("login_with")} Facebook</span>
          </FacebookLoginButton>
          <div className="line-with-text">
            <hr className="line" />
            <span className="text">{t("or")}</span>
            <hr className="line" />
          </div>
          <GoogleLoginButton onClick={() => loginWithGoogle()}>
            <span>{t("login_with")} Google</span>
          </GoogleLoginButton>
        </div>
      </div>
    </div>
  );
}

export default LoginForm;
