import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper";
import { Link, useParams } from "react-router-dom";
import { FaShoppingCart } from "react-icons/fa";
import "./SliderPack.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material";

const SliderPack = ({ data, t }) => {
  const [cookies, setCookie] = useCookies(["cart"]);
  const { site } = useParams();

  function convertToThumbnailUrl(url) {
    // Extract the directory, filename, and extension
    const parts = url.split("/"); // Split the URL into segments
    const filename = parts.pop(); // Get the filename
    const [name, ext] = filename.split("."); // Split into name and extension

    // Insert "thumbnails" into the path just before the filename
    parts.push("thumbnails"); // Add "thumbnails" directory to the path
    const thumbnailPath = `${parts.join("/")}/${name}_thumbnail.${ext}`; // Construct the new URL

    return thumbnailPath;
  }

  const addToCart = (item) => {
    const cart = cookies["cart"] || [];
    if (cart.includes(item.id)) return;
    cart.push(item.id);
    setCookie("cart", cart, { path: "/" });
  };

  const handleViewCart = () => {
    window.location.href = `/cart/${t("lang")}`;
  };

  return (
    <div className="slider-container">
      <Swiper
        spaceBetween={10}
        slidesPerView={2}
        dir={t("lang") === "ar" ? "rtl" : "ltr"}
        key={t("lang")}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        modules={[Navigation, Thumbs, Autoplay]}
        navigation
        thumbs={{ autoScrollOffset: 1 }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: true,
        }}
      >
        {!data ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          data.length > 0 &&
          data.map((item) => (
            <SwiperSlide key={item.id}>
              <Link
                className="slide-content"
                to={`/site/${site}/pack/${item.link}/${t("lang")}`}
              >
                <img
                  className="slide-image"
                  src={convertToThumbnailUrl(item.image)}
                  alt={item.name || item.location_name}
                />
                <div className="label">
                  <div className="label-background"></div>
                  <div className="label-text">
                    {item.name || item.location_name}
                  </div>
                </div>
              </Link>
              <div className="btn-container-slider">
                {cookies["cart"] && cookies["cart"].includes(item.id) ? (
                  <button className="btn" onClick={handleViewCart}>
                    <FaShoppingCart size={20} />
                    {t("view_in_cart")}
                  </button>
                ) : (
                  <button className="btn" onClick={() => addToCart(item)}>
                    <FaShoppingCart size={20} />
                    {t("add_to_cart")}
                  </button>
                )}
                <button className="btn-light">
                  <span className="crossed-out-price">
                    {item.total_price + " " + t("currency")}{" "}
                  </span>
                  &nbsp;{item.discounted_price + " " + t("currency")}
                </button>
              </div>
            </SwiperSlide>
          ))
        )}
      </Swiper>
    </div>
  );
};

export default SliderPack;
