import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { orderService } from "../services/payment";
import DescriptionLocation from "../components/LocationComponents/DescriptionLocation";
import {
  loadLocationProductByLinks,
  loadSiteByLink,
} from "../services/loadData";
import "./main.css";
import "../components/LocationComponents/DescriptionLocationStyle.css";

const Location = ({ t }) => {
  const [locationData, setLocationData] = useState({});
  const { site: rawSite, location: rawLocation } = useParams();

  // Normalize 'site' and 'location' parameters
  const site = rawSite.trim().toLowerCase();
  const location = rawLocation.trim().toLowerCase();

  // Order function
  const order = async () => {
    const idArray = [locationData.product_id];
    const url = await orderService(idArray, t("lang"));
    setTimeout(() => {
      window.open(url, "_blank");
    });
  };

  // Fetch site and location data
  const fetchData = async () => {
    try {
      const siteData = await loadSiteByLink(site, t("lang"));

      if (!siteData) {
        redirectToNotFound();
        return;
      }

      const locationData = await loadLocationProductByLinks(
        site,
        location,
        t("lang")
      );

      if (!locationData) {
        redirectToNotFound();
        return;
      }

      setLocationData(locationData);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        redirectToNotFound();
      }
    }
  };

  // Redirect to NotFound page
  const redirectToNotFound = () => {
    window.location.href = "/not-found";
  };

  useEffect(() => {
    fetchData();
  }, [site, location, t]);

  // Compute JSON-LD using useMemo for efficiency
  const jsonld = useMemo(() => {
    if (!locationData.name) return null;

    return {
      "@context": "http://schema.org",
      "@type": "Product",
      productID: locationData.product_id,
      name: locationData.name,
      description: locationData.description,
      url: window.location.href,
      offers: {
        "@type": "Offer",
        availability: "http://schema.org/InStock",
        price: locationData.price,
        priceCurrency: "TND",
        url: window.location.href,
      },
      brand: {
        "@type": "Brand",
        name: "Historiar",
      },
      category: "AR/VR",
      audience: {
        "@type": "Audience",
        audienceType: "Travel Enthusiasts", // Target audience
      },
      duration: {
        "@type": "QuantitativeValue",
        value: locationData.duration,
        unitText: "HOURS",
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        reviewCount: "12",
      },
      image: {
        "@type": "ImageObject",
        url: `https://historiar.io/${locationData.image}`,
        image: `https://historiar.io/${locationData.image}`,
        name: locationData.name,
        width: 1024,
        height: 1024,
      },
    };
  }, [locationData]);

  return (
    <div className="site">
      {locationData.name && (
        <Helmet>
          <title>{locationData.name} - Historiar</title>
          <meta name="description" content={locationData.description} />
          {jsonld && (
            <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
          )}
        </Helmet>
      )}

      <div className="bkg">
        <div>
          <div className="container">
            <img
              className="bkg-img"
              src={locationData.image}
              alt="Background"
            />
          </div>
          <div className="content-site">
            <DescriptionLocation
              t={t}
              data={locationData.details || {}}
              price={locationData.discounted_price}
              duration={locationData.duration}
              order={order}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Location;
