import React, { useEffect, useState } from "react";
import CartContent from "../components/CartComponents/CartContent";
import { Helmet } from "react-helmet";
import { loadProductsByIds } from "../services/loadData";
import { useCookies } from "react-cookie";
import { orderService } from "../services/payment";
import "./main.css";
import "../components/CartComponents/CartStyle.css";

function Cart({ t }) {
  const [cookies] = useCookies(["cart"]);
  const [products, setProducts] = useState(null);

  const order = async () => {
    const url = await orderService(cookies["cart"], t("lang"));
    setTimeout(() => {
      window.open(url, "_blank");
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!cookies["cart"]) {
        setProducts([]);
        return;
      }

      try {
        const tmpData = await loadProductsByIds(cookies["cart"], t("lang"));

        if (!tmpData || tmpData.length === 0) {
          setProducts([]);
          return;
        }

        setProducts(tmpData);
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      }
    };

    fetchData();
  }, [cookies, t]);

  return (
    <>
      <Helmet>
        <title>{t("cart")} - Historiar</title>
      </Helmet>
      <div className="bg-img-cart">
        <CartContent products={products} order={order} t={t} />
      </div>
    </>
  );
}

export default Cart;
