import axios from "axios";

const makeApiRequest = async (url, data = null) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_PATH}${url}`,
      data,
      { withCredentials: true } // Send cookies with the request
    );

    return response.data; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token expired, try refreshing the token
      await refreshAccessToken();
      return makeApiRequest(url, data); // Retry the request after refreshing the token
    } else {
      console.error("Error making the request:", error);
      throw error; // Rethrow error if not 401
    }
  }
};

// Function to refresh the access token using the refresh token stored in cookies
const refreshAccessToken = async () => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_PATH}/users/refresh`,
      {},
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw new Error("Failed to refresh access token");
  }
};

const orderService = async (product_ids, language) => {
  try {
    const response = await makeApiRequest("/payment/pay", {
      product_ids: product_ids,
      lang: language,
    });

    return response.result.link;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export { orderService };
