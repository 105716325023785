import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./MapPackStyle.css";
import "mapbox-gl/dist/mapbox-gl.css";

const MapPack = ({ locations, t }) => {
  const mapRef = useRef();
  const mapContainerRef = useRef();
  const [coordinatesFound, setCoordinatesFound] = useState(false);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

    // Set RTL plugin if needed
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable")
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.3.0/mapbox-gl-rtl-text.js",
        null,
        true
      );

    if (locations)
      for (const location of Object.values(locations)) {
        const coordsExist = location.latitude && location.longitude;
        if (coordsExist) setCoordinatesFound(true);

        mapRef.current = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/streets-v12",
          center: coordsExist
            ? [location.longitude, location.latitude]
            : [9.555505, 34.689183],
          zoom: coordsExist ? 15 : 4,
          language: t("lang"),
          interactive: false,
        });

        if (coordsExist) {
          new mapboxgl.Marker()
            .setLngLat([location.longitude, location.latitude])
            .addTo(mapRef.current);
        }

        return () => {
          mapRef.current.remove();
        };
      }
  }, [locations, t]);

  // Function to open Google Maps with directions
  const openGoogleMaps = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        if (latitude && longitude) {
          const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${latitude},${longitude}&destination=${locations[0].latitude},${locations[0].longitude}`;
          // Dynamically create an anchor element and simulate a click to open the URL in a new tab
          const link = document.createElement("a");
          link.href = googleMapsUrl;
          link.target = "_blank";
          link.rel = "noopener noreferrer";
          link.click();
        } else {
          alert("Please allow location access to get directions.");
        }
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  return (
    <div className="map-pack-container">
      <div className="map-container" ref={mapContainerRef} />
      {coordinatesFound && (
        <center>
          <button className="btn" onClick={openGoogleMaps}>
            {t("directions")}
          </button>
        </center>
      )}
    </div>
  );
};

export default MapPack;
