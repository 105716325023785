import { useEffect, useState } from "react";
import { Parallax } from "react-scroll-parallax";
import "./ParallaxAccueilStyle.css";
import SliderHome from "./SliderHome";

const ParallaxAccueil = ({ t, sites, locations }) => {
  const [filteredData, setFilteredData] = useState(sites);
  const direction = t("lang") === "ar" ? "rtl" : "ltr";

  // Filter sites on every change of sites or locations
  useEffect(() => {
    // Example filter condition: filter sites that have a name (if needed)
    const filtered = sites.filter(({ name }) => name && name.toLowerCase());
    setFilteredData(filtered);
  }, [sites, locations]);

  return (
    <Parallax speed={-40} className="parallax-container">
      <div className="slider-accueil">
        <h1
          className="title-accueil"
          style={{
            direction: direction,
            color: "#f9db1f",
            textTransform: "uppercase",
          }}
        >
          {t("xr_experiences")}{" "}
          <span className="span-accueil">{"9 " + t("vr_ar_visits")}</span>
        </h1>
        <br />
        <h2 className="title-accueil" style={{ direction: direction }}>
          {t("archeological_sites")}
        </h2>
        <SliderHome data={filteredData} t={t} />
      </div>
    </Parallax>
  );
};

export default ParallaxAccueil;
