import React, { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet";
import VisitsContent from "../components/VisitsComponents/VisitsContent";
import "./main.css";
import "../components/VisitsComponents/VisitsStyle.css";
import {
  loadProductsByIds,
  loadProductsByVoucher,
  loadVouchersByUser,
} from "../services/loadData";
import { useCookies } from "react-cookie";
import { useSearchParams } from "react-router-dom";
import { Snackbar } from "@mui/material";

function Visits({ t }) {
  const [searchParams] = useSearchParams();
  const [cookies, setCookie] = useCookies(["vouchers", "cart"]);
  const [products, setProducts] = useState(null);
  const [openSnackBar, setOpenSnackbar] = useState(false);

  // Remove products from the cart based on productIds
  const deleteFromCart = (productIds) => {
    let cart = cookies["cart"] || [];
    cart = cart.filter((item) => !productIds.includes(item));
    setCookie("cart", cart, { path: "/" });
  };

  const addVouchersToCookies = (vouchers) => {
    // Find the latest voucher and set its expiration date as the cookie expiration date
    const latestVoucher = vouchers.reduce((latest, current) => {
      return new Date(current.expiration_date) >
        new Date(latest.expiration_date)
        ? current
        : latest;
    });

    setCookie("vouchers", vouchers, {
      path: "/",
      secure: true,
      sameSite: "Strict",
      expires: new Date(latestVoucher.expiration_date),
    });
  };

  // Fetch all products based on the vouchers stored in cookies
  const fetchProductsFromCookies = async () => {
    const visitProducts = [];
    for (const voucher of cookies["vouchers"] || []) {
      try {
        const voucherData = await loadProductsByVoucher(voucher.code);
        if (voucherData.products) {
          const productData = await loadProductsByIds(
            voucherData.products,
            t("lang")
          );
          productData.forEach((product) => (product.voucher = voucher.code));
          visitProducts.push(...productData);
        }
      } catch (error) {
        console.error("Error loading products by voucher:", voucher.code);
      }
    }
    setProducts(visitProducts);
  };

  // Fetch available vouchers from the backend (for logged-in users)
  const fetchVouchersFromDB = async () => {
    try {
      const response = await loadVouchersByUser();
      return response.ok ? response.data : null;
    } catch (error) {
      console.error("Error fetching vouchers from the DB:", error);
      return null;
    }
  };

  // Main logic for fetching data based on query params and user login status
  useEffect(() => {
    const fetchData = async () => {
      let vouchers = [];

      const voucherParam = searchParams.get("voucher");
      const vouchersFromDB = await fetchVouchersFromDB();
      let voucherData = null;

      const voucherInCookies = cookies["vouchers"]
        ? cookies["vouchers"].filter((voucher) => voucher.code === voucherParam)
        : [];

      if (voucherParam && voucherInCookies.length === 0) {
        // If there is a voucher query param, fetch the products related to it to activate it
        voucherData = await loadProductsByVoucher(voucherParam);
      }

      // If user is logged in and vouchers are fetched, add them to cookies
      if (vouchersFromDB && vouchersFromDB.length > 0) {
        vouchersFromDB.forEach((voucher) => {
          vouchers.push({
            code: voucher.voucher_code,
            expiration_date: new Date(voucher.expiration_date).toISOString(),
          });
        });

        if (voucherParam) setOpenSnackbar(true);
      } else if (voucherParam && voucherInCookies.length === 0) {
        vouchers = cookies["vouchers"] || [];

        if (voucherData.products) {
          setOpenSnackbar(true);
          deleteFromCart(voucherData.products);

          vouchers.push({
            code: voucherData.voucher_code,
            expiration_date: new Date(
              voucherData.expiration_date
            ).toISOString(),
          });
        }
      } else {
        // If there are no vouchers, fetch the products from the cookies
        fetchProductsFromCookies();
        return;
      }

      addVouchersToCookies(vouchers);
    };

    fetchData();
  }, [searchParams, t]);

  // Retrieve products from the vouchers stored in cookies
  useEffect(() => {
    fetchProductsFromCookies();
  }, [cookies]);

  return (
    <Fragment>
      <Helmet>
        <title>{`${t("visits")} - Historiar`}</title>
      </Helmet>
      <div className="bg-img-visits">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={openSnackBar}
          onClose={() => setOpenSnackbar(false)}
          message={t("productAdded")}
        />
        <VisitsContent products={products} t={t} />
      </div>
    </Fragment>
  );
}

export default Visits;
