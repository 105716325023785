import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const DeleteUser = () => {
  const { userID } = useParams();

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    width: "100vw",
  };

  const contentStyle = {
    textAlign: "center",
  };

  return (
    <div style={containerStyle}>
      <Helmet>
        <title>{userID ? "User ID not found" : "User ID is required"}</title>
      </Helmet>
      <div style={contentStyle}>
        <h1>{userID ? "User ID not found." : "User ID is required."}</h1>
      </div>
    </div>
  );
};

export default DeleteUser;
