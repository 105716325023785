import React from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import "./SearchBar.css";

function SearchBar({ t, sites, locations }) {
  const navigate = useNavigate();

  // Combine sites and locations into a single list
  const searchOptions = [
    // Add sites to the options
    ...sites.map((site) => ({
      type: "site", // Tag to differentiate between site and location
      group: t("sites"),
      title: site.name,
      siteLink: site.link,
    })),
    // Add locations to the options
    ...locations.map((location) => {
      const site = sites.find((s) => s.id === location.site_id); // Find the parent site
      return {
        type: "location",
        title: location.location_name,
        group: site ? site.name : "Unknown Site", // Group by site name
        siteLink: site?.link,
        subsiteLink: location.link,
      };
    }),
  ];

  // Handle user selection
  const handleInputChange = (event, value) => {
    if (value) {
      if (value.type === "site") {
        // Navigate to the site page
        navigate(`/site/${value.siteLink}/${t("lang")}`);
      } else if (value.type === "location") {
        // Navigate to the location page
        navigate(
          `/site/${value.siteLink}/location/${value.subsiteLink}/${t("lang")}`
        );
      }
    }
  };

  return (
    <Autocomplete
      freeSolo
      onChange={handleInputChange}
      className="autocomplete"
      id="search-bar"
      options={searchOptions} // Use the combined options
      groupBy={(option) => option.group || null} // Group by site name for locations
      getOptionLabel={(option) => option.title} // Display site or location name
      renderInput={(params) => (
        <TextField
          {...params}
          className="searchBar"
          variant="filled"
          label={t("search") + "..."}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                <InputAdornment position="end" className="searchIcon">
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default SearchBar;
