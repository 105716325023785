import * as React from "react";
import {
  CircularProgress,
  createTheme,
  IconButton,
  ThemeProvider,
} from "@mui/material";
import { useCookies } from "react-cookie";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import { CiCreditCard1 } from "react-icons/ci";
import "./CartStyle.css";
import { Link } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Light blue for primary elements
    },
    background: {
      default: "#121212", // Dark background color
      paper: "#1d1d1d", // Dark paper background
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#b0b0b0", // Secondary text (lighter grey)
    },
  },
});

const CartContent = ({ products, order, t }) => {
  const [cookies, setCookie] = useCookies(["cart"]);

  function convertToThumbnailUrl(url) {
    // Extract the directory, filename, and extension
    const parts = url.split("/"); // Split the URL into segments
    const filename = parts.pop(); // Get the filename (e.g., "carthage.jpg")
    const [name, ext] = filename.split("."); // Split into name and extension

    // Insert "thumbnails" into the path just before the filename
    parts.push("thumbnails"); // Add "thumbnails" directory to the path
    const thumbnailPath = `${parts.join("/")}/${name}_thumbnail.${ext}`; // Construct the new URL

    return thumbnailPath;
  }

  const deleteItem = (id) => {
    let newCart = cookies["cart"].filter((item) => item !== id);
    setCookie("cart", newCart, { path: "/" });
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="cart-content-container">
        <div className="cart-card">
          <center>
            <h2>{t("your_cart")}</h2>
          </center>
          <br />
          <Box sx={{ padding: 2 }}>
            {!products ? (
              <center>
                <CircularProgress />
                <br />
              </center>
            ) : products.length > 0 ? (
              products.map((item, index) => (
                <Grid container spacing={2} key={index}>
                  <Box className="cart-item">
                    <Grid size={{ xs: 3 }}>
                      <Link
                        to={`/site/${item.details[0].site_link}/${
                          item.pack ? "pack" : "location"
                        }/${item.link}/${t("lang")}`}
                      >
                        <img
                          src={convertToThumbnailUrl(item.image)}
                          alt={item.name}
                        />
                      </Link>
                    </Grid>
                    <Grid size={{ xs: 4, sm: 4 }}>
                      <Link
                        to={`/site/${item.details[0].site_link}/${
                          item.pack ? "pack" : "location"
                        }/${item.link}/${t("lang")}`}
                      >
                        <p className="cart-item-name">{item.name}</p>
                      </Link>
                    </Grid>
                    <Grid size={{ xs: 3, sm: 3 }}>
                      <p className="cart-item-price">
                        {item.discounted_price.toFixed(2) +
                          " " +
                          t("currency") +
                          " / " +
                          item.duration +
                          " " +
                          t("hours")}
                      </p>
                    </Grid>
                    <Grid size={{ xs: 2 }}>
                      <IconButton
                        aria-label="delete"
                        onClick={() => deleteItem(item.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Box>
                </Grid>
              ))
            ) : (
              <center>
                {" "}
                <h3>{t("cart_is_empty")}</h3>
                <br />
                <button
                  type="submit"
                  className="btn"
                  onClick={() => window.history.back()}
                >
                  {t("go_back")}
                </button>
              </center>
            )}

            {products && products.length > 0 && (
              <div>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" }, // Center on mobile (xs) and right-align on desktop (lg)
                    marginTop: 2,
                    width: "100%", // Ensure it takes the full width
                    paddingX: 2, // Optional, add some horizontal padding for better spacing
                  }}
                >
                  <Typography variant="h6">
                    {t("total") +
                      ": " +
                      products
                        .reduce(
                          (total, product) => total + product.discounted_price,
                          0
                        )
                        .toFixed(2) +
                      " " +
                      t("currency")}
                  </Typography>
                </Box>
                <br />{" "}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-end" }, // Center on mobile (xs) and right-align on desktop (lg)
                    marginTop: 2,
                    width: "100%", // Ensure it takes the full width
                    paddingX: 2, // Optional, add some horizontal padding for better spacing
                  }}
                >
                  <button type="submit" className="btn" onClick={order}>
                    <CiCreditCard1 size={30} />
                    {t("proceed_to_checkout")}
                  </button>
                </Box>
              </div>
            )}
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default CartContent;
