import axios from "axios";

// Helper function to handle API requests with token management
const makeApiRequest = async (method, url, data = null) => {
  try {
    const response = await axios({
      method,
      url: `${process.env.REACT_APP_API_PATH}${url}`,
      data,
      withCredentials: true, // Send cookies with the request
    });

    return response.data; // Return the response data
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token expired, try refreshing the token
      await refreshAccessToken();
      return makeApiRequest(method, url, data); // Retry the request after refreshing the token
    } else {
      console.error("Error making the request:", error);
      throw error; // Rethrow error if not 401
    }
  }
};

// Function to refresh the access token using the refresh token stored in cookies
const refreshAccessToken = async () => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_PATH}/users/refresh`,
      {},
      {
        withCredentials: true,
      }
    );

    return true;
  } catch (error) {
    console.error("Error refreshing access token:", error);
    throw new Error("Failed to refresh access token");
  }
};

// Function to authenticate user and set access token in cookies
const authenticationService = async (userData) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_PATH}/users/authentication`,
      userData,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true, // Cookies are set by the server
      }
    );

    return { ok: true };
  } catch (error) {
    return { ok: false };
  }
};

// Function to logout and clear JWT cookies
const logoutService = async () => {
  try {
    await axios.delete(`${process.env.REACT_APP_API_PATH}/users/logout`, {
      withCredentials: true,
    });
    return { ok: true };
  } catch (error) {
    console.error("Error logging out:", error);
    return { ok: false };
  }
};

// Function to fetch user data
const fetchUserData = async () => {
  try {
    const userData = await makeApiRequest("GET", "/users/get-user-data");
    userData.ok = true; // Add a flag to indicate success
    return userData; // Return the user data
  } catch (error) {
    return { ok: false };
  }
};

export {
  authenticationService,
  refreshAccessToken,
  logoutService,
  fetchUserData,
};
