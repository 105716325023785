import * as React from "react";
import { CircularProgress, createTheme, ThemeProvider } from "@mui/material";
import { Box } from "@mui/material";
import "./VisitsStyle.css";
import CardVisits from "./CardVisits";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#90caf9", // Light blue for primary elements
    },
    background: {
      default: "#121212", // Dark background color
      paper: "#1d1d1d", // Dark paper background
    },
    text: {
      primary: "#ffffff", // White text
      secondary: "#b0b0b0", // Secondary text (lighter grey)
    },
  },
});

const VisitsContent = ({ products, t }) => {
  return (
    <ThemeProvider theme={darkTheme}>
      <div className="visits-content-container">
        <div className="visits-card">
          <center>
            <h2>{t("your_visits")}</h2>
          </center>
          <br />
          <Box>
            {!products ? (
              <center>
                <br />
                <CircularProgress />
                <br />
              </center>
            ) : products.length > 0 ? (
              <div className="card-flex">
                {products.map((product, index) => (
                  <CardVisits key={index} data={product} t={t} />
                ))}
              </div>
            ) : (
              <center className="no-visits">
                <br />
                <h3>{t("no_visits")}</h3>
                <br />
                <button
                  type="submit"
                  className="btn"
                  onClick={() => window.history.back()}
                >
                  {t("go_back")}
                </button>
              </center>
            )}
          </Box>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default VisitsContent;
