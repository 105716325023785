import React from "react";
import { Grid, Container } from "@mui/material";
import { FaShoppingCart } from "react-icons/fa";
import { useCookies } from "react-cookie";
import Comparison from "../Common/Comparison";
import MapLocation from "./MapLocation";
import "./DescriptionLocationStyle.css";

const DescriptionLocation = ({ t, data, price, duration, order }) => {
  const [cookies, setCookie] = useCookies(["cart"]);

  // Add the location to the cart if it's not already there
  const addToCart = () => {
    const cart = cookies["cart"] || [];

    if (!cart.includes(data.product_id)) {
      cart.push(data.product_id);
      setCookie("cart", cart, { path: "/" });
    }
  };

  // Conditional rendering based on language direction (RTL or LTR)
  const directionStyle = t("lang") === "ar" ? "rtl" : "ltr";

  // Button to redirect to cart if item is already in cart
  const redirectToCart = () => {
    window.location.href = `/cart/${t("lang")}`;
  };

  const {
    location_name,
    coming_soon,
    description,
    before_image,
    after_image,
    longitude,
    latitude,
    id,
  } = data;

  return (
    <Container maxWidth="xl" style={{ direction: directionStyle }}>
      <div>
        <h1 className="title-site">
          {location_name}
          {coming_soon && <span className="coming-soon">(coming soon)</span>}
        </h1>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <p className="description-site">{description}</p>
          <br />

          {!coming_soon && (
            <div className="btn-group">
              <button className="btn" id="btn-site" onClick={order}>
                {`${t("watch_now")} (${price} ${t(
                  "currency"
                )} / ${duration} ${t("hours")})`}
              </button>

              {cookies["cart"]?.includes(id) ? (
                <button className="btn" id="btn-site" onClick={redirectToCart}>
                  <FaShoppingCart size={20} />
                  {t("view_in_cart")}
                </button>
              ) : (
                <button className="btn" id="btn-site" onClick={addToCart}>
                  <FaShoppingCart size={20} />
                  {t("add_to_cart")}
                </button>
              )}
            </div>
          )}
        </Grid>

        <Grid item xs={12} md={6}>
          {before_image && after_image && (
            <Comparison t={t} before={before_image} after={after_image} />
          )}
          <br />
          <MapLocation
            coordinates={longitude && latitude ? [longitude, latitude] : null}
            language={t("lang")}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default DescriptionLocation;
