import React, { useState, useEffect } from "react";
import "./NavbarStyle.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import LanguageSwitcher from "./LanguageSwitcher";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import TourIcon from "@mui/icons-material/Tour";
import { Badge } from "@mui/material";
import { useCookies } from "react-cookie";
import { loadProductsByVoucher } from "../../services/loadData";

const Navbar = ({ loggedIn, logout, t }) => {
  const [cookies, setCookie] = useCookies(["cart", "vouchers"]);
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const [visitsCounts, setVisitsCounts] = useState(0);
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      var count = 0;

      if (cookies["vouchers"]) {
        for (const voucher of cookies["vouchers"]) {
          if (new Date(voucher.expiration_date) > new Date()) {
            const response = await loadProductsByVoucher(voucher.code);
            if (response.ok) {
              count += response.products.length;
            }
          }
        }
      }
      setVisitsCounts(count);
    };

    fetch();
  }, [cookies["vouchers"]]);

  useEffect(() => {
    setCart(cookies["cart"] ? cookies["cart"] : []);
  }, [cookies["cart"]]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const NavigateToCart = () => {
    navigate(`/cart/${t("lang")}`);
  };

  const NavigateToLogin = () => {
    navigate(`/login/${t("lang")}`);
  };

  const NavigateToVisits = () => {
    navigate(`/visits/${t("lang")}`);
  };

  const Logout = () => {
    logout();
  };

  return (
    <nav className={`navbar ${isScrolled ? "scrolled" : ""}`}>
      <div className="navbar-container">
        <div className="logo">
          <Link to={`/${t("lang")}`} className="link-logo">
            <img
              className="Logo-image"
              src={logo}
              alt="Logo Historiar"
              width="90"
            />
          </Link>
        </div>
        <div className="menu">
          <div className="menu-items">
            <button
              className="btn-light"
              id="btn-navbar"
              onClick={NavigateToVisits}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent={visitsCounts}
                color="error"
              >
                <TourIcon />
              </Badge>
              <span className="menu-text">&nbsp;{t("visits")}</span>
            </button>
            <button
              className="btn-light"
              id="btn-navbar"
              onClick={NavigateToCart}
            >
              <Badge
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                badgeContent={cart.length}
                color="error"
              >
                <ShoppingCartIcon />
              </Badge>
              <span className="menu-text">&nbsp;{t("cart")}</span>
            </button>
            {loggedIn == false && (
              <button
                className="btn-light"
                id="btn-navbar"
                onClick={NavigateToLogin}
              >
                <LoginIcon />
                <span className="menu-text">&nbsp;{t("login")}</span>
              </button>
            )}
            {loggedIn && (
              <button className="btn-light" id="btn-navbar" onClick={Logout}>
                <LogoutIcon />
                <span className="menu-text">&nbsp;{t("logout")}</span>
              </button>
            )}

            <div style={{ marginLeft: "1em" }}>
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
