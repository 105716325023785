import React, { useEffect, useState } from "react";
import BgAccueil from "../components/AccueilComponents/BgAccueil";
import HomeComponent from "../components/AccueilComponents/HomeComponent";
import { ParallaxProvider } from "react-scroll-parallax";
import { Helmet } from "react-helmet";
import "./main.css";
import ParallaxAccueil from "../components/AccueilComponents/ParallaxAccueil";
import { loadLocations } from "../services/loadData";

const Accueil = ({ t, sites }) => {
  const [locations, setLocations] = useState([]);

  // Load locations when the language changes
  useEffect(() => {
    const fetchLocations = async () => {
      const locationData = await loadLocations(t("lang"));
      setLocations(locationData);
    };
    fetchLocations();
  }, [t]);

  return (
    <ParallaxProvider>
      <Helmet>
        <title>Historiar</title>
      </Helmet>
      <div className="top">
        <BgAccueil t={t} sites={sites} locations={locations} />
        <div className="line" />
      </div>
      <br />
      <ParallaxAccueil t={t} sites={sites} locations={locations} />
      <div className="top">
        <div className="line" />
        <HomeComponent t={t} />
      </div>
    </ParallaxProvider>
  );
};

export default Accueil;
