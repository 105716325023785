import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FaShoppingCart } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { loadPackProductByLinks } from "../services/loadData";
import DescriptionPack from "../components/PackComponents/DescriptionPack";
import { orderService } from "../services/payment";
import "./main.css";
import "../components/PackComponents/DescriptionPackStyle.css";

const Pack = ({ t }) => {
  const [cookies, setCookie] = useCookies(["cart"]);
  const [packData, setPackData] = useState({});
  const { site, pack } = useParams();

  // Fetch pack data based on URL params
  const fetchPackData = async () => {
    try {
      const packInfo = await loadPackProductByLinks(site, pack, t("lang"));
      if (packInfo) setPackData(packInfo);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        window.location.href = "/not-found";
      }
    }
  };

  useEffect(() => {
    fetchPackData();
  }, [site, pack, t]);

  const order = async () => {
    const idArray = [packData.product_id];
    const url = await orderService(idArray, t("lang"));
    setTimeout(() => {
      window.open(url, "_blank");
    });
  };

  // Add the pack to the cart
  const addToCart = () => {
    const cart = cookies["cart"] || [];
    if (!cart.includes(packData.id)) {
      cart.push(packData.product_id);
      setCookie("cart", cart, { path: "/" });
    }
  };

  // Redirect to cart page if the item is already in the cart
  const redirectToCart = () => {
    window.location.href = `/cart/${t("lang")}`;
  };

  const { name, description, image, discounted_price, duration, id } = packData;

  // Compute JSON-LD using useMemo for efficiency
  const jsonld = useMemo(() => {
    if (!packData.name) return null;

    return {
      "@context": "http://schema.org",
      "@type": "Product",
      productID: packData.product_id,
      name: packData.name,
      description: packData.description,
      url: window.location.href,
      offers: {
        "@type": "Offer",
        availability: "http://schema.org/InStock",
        price: packData.price,
        priceCurrency: "TND",
        url: window.location.href,
      },
      brand: {
        "@type": "Brand",
        name: "Historiar",
      },
      category: "AR/VR",
      audience: {
        "@type": "Audience",
        audienceType: "Travel Enthusiasts", // Target audience
      },
      duration: {
        "@type": "QuantitativeValue",
        value: packData.duration,
        unitText: "HOURS",
      },
      review: {
        "@type": "Review",
        reviewRating: {
          "@type": "Rating",
          ratingValue: "5",
          bestRating: "5",
        },
      },
      aggregateRating: {
        "@type": "AggregateRating",
        ratingValue: "5",
        reviewCount: "12",
      },
      image: {
        "@type": "ImageObject",
        url: `https://historiar.io/${packData.image}`,
        image: `https://historiar.io/${packData.image}`,
        name: packData.name,
        width: 1024,
        height: 1024,
      },
    };
  }, [packData]);

  return (
    <div>
      {name && (
        <Helmet>
          <title>{`${name} - Historiar`}</title>
          <meta name="description" content={description} />
          {jsonld && (
            <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
          )}
        </Helmet>
      )}

      <div className="pack-container">
        <div className="image-container-pack">
          <img src={image} alt="Pack" className="image-pack" />
          <div className="overlay-pack">
            <h1 className="title-pack">{name}</h1>
            <hr />
            <p className="description-pack">{description}</p>

            <div className="btns-pack">
              <button className="btn" id="btn-pack" onClick={order}>
                {`${t("watch_now")} (${discounted_price} ${t(
                  "currency"
                )} / ${duration} ${t("hours")})`}
              </button>

              {cookies["cart"]?.includes(id) ? (
                <button className="btn" onClick={redirectToCart}>
                  <FaShoppingCart size={20} />
                  {t("view_in_cart")}
                </button>
              ) : (
                <button className="btn" onClick={addToCart}>
                  <FaShoppingCart size={20} />
                  {t("add_to_cart")}
                </button>
              )}
            </div>
          </div>
          <div className="line" style={{ marginTop: "-10px" }} />
        </div>

        <br />
        <DescriptionPack t={t} data={packData} />
      </div>
    </div>
  );
};

export default Pack;
