import React from "react";
import Grid from "@mui/material/Grid2";
import {
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaTiktok,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import "./FooterStyle.css";

const Footer = ({ t, data }) => {
  const currentYear = new Date().getFullYear();
  const isRTL = t("lang") === "ar";

  return (
    <div className={`footer ${isRTL ? "rtl" : ""}`}>
      <div className="footer-container">
        {/* Footer Links */}
        <Grid container spacing={5} justifyContent="center">
          <Grid size={{ xs: 12, sm: 12, md: 3 }} className="footer-section">
            <Link to={`/${t("lang")}`}>
              <div className="address">
                <img src="/logo-historiar-light.png" alt="logo historiar" />
                <h4>HISTORIAR</h4>
              </div>
            </Link>
            <ul>
              <li>
                <center>
                  <Link to={`/privacy-policy/${t("lang")}`}>
                    {t("privacy_policy")}
                  </Link>
                </center>
              </li>
            </ul>
          </Grid>

          {/* Policy and terms Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("about_us")}</h4>
            <p style={{ textAlign: isRTL ? "right" : "left" }}>
              <Link to={"/" + t("lang")}>
                <strong>Historiar</strong>
              </Link>{" "}
              {t("about_us_text")}
            </p>
          </Grid>

          {/* Social Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("sites")}</h4>
            <ul>
              {data?.map((site, index1) => (
                <li key={`${site.link}-${index1}`}>
                  <Link
                    to={`/site/${site.link}/${t("lang")}`}
                    style={{ color: "#f9db1f" }}
                  >
                    {site.name}
                  </Link>
                  {site.locations && (
                    <div style={{ marginLeft: isRTL ? "auto" : "1em" }}>
                      <ul>
                        {site.locations?.map((location, index2) => (
                          <li key={index2} style={{ marginBottom: "-0.3em" }}>
                            <Link
                              to={`/site/${site.link}/location/${
                                location.link
                              }/${t("lang")}`}
                            >
                              ‣ {location.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </Grid>

          {/* Get in touch Section */}
          <Grid size={{ xs: 12, sm: 4, md: 3 }} className="footer-section">
            <h4>{t("get_in_touch")}</h4>
            <div className="social-icons">
              <Link
                to="https://www.facebook.com/p/HISTORIAR-100063614228567/"
                target="_blank"
                className="icon"
              >
                <FaFacebook size={30} />
              </Link>
              <Link
                to="https://twitter.com/HistoriarSa"
                target="_blank"
                className="icon"
              >
                <FaTwitter size={30} />
              </Link>
              <Link
                to="https://www.linkedin.com/company/historiar/"
                target="_blank"
                className="icon"
              >
                <FaLinkedin size={30} />
              </Link>
              <Link
                to="https://www.instagram.com/historiar.io/"
                target="_blank"
                className="icon"
              >
                <FaInstagram size={30} />
              </Link>
              <Link
                to="https://www.tiktok.com/@historiar.io"
                target="_blank"
                className="icon"
              >
                <FaTiktok size={30} />
              </Link>
            </div>
            <br />
            <ul>
              <li>
                <Link to="mailto: contact@historiar.io">
                  contact@historiar.io
                </Link>
              </li>
              <li>
                <Link to="tel:+21622585448">+216 22 585 448</Link>
              </li>
            </ul>
          </Grid>
        </Grid>

        {/* Footer Copyright */}
        <p>
          <Link to={"/" + t("lang")}>HISTORIAR</Link> © {currentYear}
        </p>
      </div>
    </div>
  );
};

export default Footer;
