import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./routes/Login";
import Signin from "./routes/Signup";
import Accueil from "./routes/Accueil";
import PaymentError from "./routes/PaymentError";
import Site from "./routes/Site";
import NotFound from "./routes/NotFound";
import Location from "./routes/Location";
import Pack from "./routes/Pack";
import ScrollToTop from "./components/Common/ScrollToTop";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import { useCookies } from "react-cookie";
import Cart from "./routes/Cart";
import { useEffect, useState } from "react";
import DeleteUser from "./routes/DeleteUser";
import Visits from "./routes/Visits";

function App({ t, sites, loggedIn }) {
  const [cookies, setCookie] = useCookies(["language", "access_token"]);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    if (cookies["language"]) {
      setLanguage(cookies["language"]);
    }
  }, [cookies]);

  return (
    <div>
      <ScrollToTop />
      <Routes>
        {/* Redirect to "/language" if no language is specified */}
        <Route path="/" element={<Navigate to={`/${language}`} replace />} />
        <Route
          path="/login"
          element={<Navigate to={`/login/${language}`} replace />}
        />
        <Route
          path="/cart"
          element={<Navigate to={`/cart/${language}`} replace />}
        />
        <Route
          path="/visits"
          element={<Navigate to={`/visits/${language}`} replace />}
        />
        <Route
          path="/privacy-policy"
          element={<Navigate to={`/privacy-policy/${language}`} replace />}
        />
        <Route
          path="/payment-error"
          element={<Navigate to={`/payment-error/${language}`} replace />}
        />

        {/* Routes with language */}
        <Route path="/:language" element={<Accueil t={t} sites={sites} />} />
        <Route
          path="/login/:language"
          element={
            loggedIn ? (
              <Navigate to={`/${language}`} replace />
            ) : (
              <Login t={t} />
            )
          }
        />
        <Route path="/cart/:language" element={<Cart t={t} />} />
        <Route path="/visits/:language" element={<Visits t={t} />} />

        <Route
          path="/privacy-policy/:language"
          element={<PrivacyPolicy t={t} />}
        />
        <Route
          path="/site/:site/:language"
          element={<Site t={t} sites={sites} />}
        />
        <Route
          path="/site/:site/location/:location/:language"
          element={<Location t={t} sites={sites} />}
        />
        <Route
          path="/site/:site/pack/:pack/:language"
          element={<Pack t={t} />}
        />
        <Route
          path="/payment-error/:language"
          element={<PaymentError t={t} />}
        />
        <Route path="/delete-user/:userID" element={<DeleteUser />} />

        {/* 404 Route */}
        <Route path="/not-found" element={<NotFound t={t} />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </div>
  );
}

export default App;
