import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DescriptionContenu from "../components/SiteComponents/DescriptionSite";
import { useParams } from "react-router-dom";
import { loadProductsBySiteLink, loadSiteByLink } from "../services/loadData";
import SliderLocation from "../components/SiteComponents/SliderLocation";
import "./main.css";
import "../components/SiteComponents/DescriptionSiteStyle.css";
import SliderPack from "../components/SiteComponents/SliderPack";

const Site = ({ t }) => {
  const { site } = useParams();
  const [siteData, setSiteData] = useState({});
  const [locationsData, setLocationsData] = useState(null);
  const [packsData, setPacksData] = useState(null);
  const [comingSoon, setComingSoon] = useState(true);

  // Fetch site and products in parallel
  const fetchSite = async () => {
    try {
      return await loadSiteByLink(site.toLowerCase(), t("lang"));
    } catch (error) {
      console.error("Error loading site data:", error);
      return null;
    }
  };

  const fetchProducts = async () => {
    try {
      return await loadProductsBySiteLink(site.toLowerCase(), t("lang"));
    } catch (error) {
      console.error("Error loading products:", error);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const tmpSite = await fetchSite();
      if (!tmpSite) {
        window.location.href = "/not-found"; // Redirect if site data is not found
        return;
      }

      setSiteData(tmpSite);

      const tmpProducts = await fetchProducts();
      if (!tmpProducts || tmpProducts.length === 0) {
        setPacksData([]);
        setLocationsData([]);
        return;
      }

      let tmpPacks = [];
      let tmpLocations = [];

      tmpProducts.forEach((product) => {
        if (product.pack) {
          tmpPacks.push(product);
        } else {
          tmpLocations.push(product);
        }
      });

      const groupedByEra = tmpProducts.reduce((acc, item) => {
        const era = item.details[0]?.era || t("locations"); // Default to "Unknown Era" if no era is specified

        if (!acc[era]) {
          acc[era] = [];
        }

        if (!item.pack) acc[era].push(item);
        return acc;
      }, {});

      setPacksData(tmpPacks);
      setLocationsData(groupedByEra);
      setComingSoon(false);
    };

    fetchData();
  }, [site, t]);

  const directionStyle = t("lang") === "ar" ? "rtl" : "ltr";

  return (
    <div className="container-contenu" style={{ direction: directionStyle }}>
      {siteData.name && (
        <Helmet>
          <title>{siteData.name} - Historiar</title>
          <meta name="description" content={siteData.description} />
        </Helmet>
      )}

      <div className="bkg">
        {/* Background image container */}
        <div className="container">
          <img className="bkg-img" src={siteData.image} alt="Background" />
        </div>

        {/* Content container */}
        <div className="content-contenu">
          <DescriptionContenu
            t={t}
            siteData={siteData}
            coming_soon={comingSoon}
          />

          <br />

          {locationsData &&
            Object.entries(locationsData).map(([era, item], index) => (
              <div key={index}>
                <h2 className="sub-title">{era}</h2>

                <div className="slider">
                  <SliderLocation data={item} t={t} />
                </div>
              </div>
            ))}

          <br />

          {packsData && packsData.length > 0 && (
            <h2 className="sub-title">{t("packs")}</h2>
          )}
          <div className="slider">
            <SliderPack data={packsData} t={t} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Site;
