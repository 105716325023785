import React, { useEffect, useState } from "react";
import { Card, Container, createTheme, ThemeProvider } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MapPack from "./MapPack";
import "./DescriptionPackStyle.css";
import { Link, useParams } from "react-router-dom";
import Comparison from "../Common/Comparison";

const theme = createTheme({ palette: { mode: "dark" } });

const DescriptionPack = ({ t, data }) => {
  let { site } = useParams();
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    if (data.locations) {
      setLocations(data.locations);
    }
  }, [data]);

  return (
    <Container style={{ direction: t("lang") === "ar" ? "rtl" : "ltr" }}>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <ThemeProvider theme={theme}>
            <Card
              variant="outlined"
              className="description-pack-container"
              style={{ textAlign: t("lang") === "ar" ? "right" : "left" }}
            >
              <p className="description-pack">
                <strong className="description-header">
                  {t("locations")} :{" "}
                </strong>
                {locations.map((location, index) => (
                  <Link
                    to={`/site/${site}/location/${location.link}/${t("lang")}`}
                    key={index}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      {location.location_name}
                    </span>
                    {index < locations.length - 1 ? ", " : "."}
                  </Link>
                ))}
              </p>

              <p className="description-pack">
                <strong className="description-header">
                  {t("description")} :{" "}
                </strong>
                {data.description}
              </p>
            </Card>
          </ThemeProvider>
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <MapPack locations={locations} t={t} />
        </Grid>
      </Grid>

      <br />
      <hr />
      <br />

      <Grid container spacing={2}>
        {locations.map((location, index) => (
          <Grid size={{ xs: 12, md: 12 / locations.length }} key={index}>
            <Link to={`/site/${site}/location/${location.link}/${t("lang")}`}>
              <strong className="description-header">
                {location.location_name}
              </strong>
            </Link>
            <Comparison
              t={t}
              before={location.before_image}
              after={location.after_image}
            />
          </Grid>
        ))}
      </Grid>
      <br />
      <br />
    </Container>
  );
};

export default DescriptionPack;
