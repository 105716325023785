import axios from "axios";

const loadSites = async (language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH + `/sites?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading sites:", error);
    throw error;
  }
};

const loadSiteByLink = async (link, language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH + `/sites/${link}?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading site by link:", error);
    throw error;
  }
};

const loadLocations = async (language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH + `/locations?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading locations:", error);
    throw error;
  }
};

const loadProductsBySiteLink = async (siteLink, language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH +
        `/products/${siteLink}?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading products by site link:", error);
    throw error;
  }
};

const loadLocationProductByLinks = async (siteLink, locationLink, language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH +
        `/products/location/${siteLink}/${locationLink}?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading location product by links:", error);
    throw error;
  }
};

const loadPackProductByLinks = async (siteLink, locationLink, language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH +
        `/products/pack/${siteLink}/${locationLink}?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading pack product by links:", error);
    throw error;
  }
};

const loadProductById = async (id, language) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH + `/products/${id}?language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading product by ID:", error);
    throw error;
  }
};

const loadProductsByIds = async (ids, language) => {
  try {
    const idsString = ids.join(","); // Convert the array to a comma-separated string
    const result = await axios.get(
      process.env.REACT_APP_API_PATH +
        `/products/products-by-ids?ids=${idsString}&language=${language}`,
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    console.error("Error loading products by IDs:", error);
    throw error;
  }
};

const loadProductsByVoucher = async (voucher) => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH +
        `/voucher/get-voucher-products/${voucher}`,
      {
        withCredentials: true,
      }
    );
    result.data.ok = true;
    return result.data;
  } catch (error) {
    return { ok: false, error: error };
  }
};

const loadVouchersByUser = async () => {
  try {
    const result = await axios.get(
      process.env.REACT_APP_API_PATH + `/voucher/get-user-vouchers`,
      {
        withCredentials: true, // Add this option to include credentials (cookies)
      }
    );
    result.ok = true;
    return result;
  } catch (error) {
    return { ok: false, error: error };
  }
};

export {
  loadSites,
  loadSiteByLink,
  loadLocations,
  loadProductsBySiteLink,
  loadLocationProductByLinks,
  loadPackProductByLinks,
  loadProductById,
  loadProductsByIds,
  loadProductsByVoucher,
  loadVouchersByUser,
};
