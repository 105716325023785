import { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { t } from "i18next";
import "./MapLocationStyle.css";
import "mapbox-gl/dist/mapbox-gl.css";

const MapLocation = ({ coordinates, language }) => {
  const mapContainerRef = useRef();
  const [userCoords, setUserCoords] = useState(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

    // Set RTL text plugin if unavailable
    if (mapboxgl.getRTLTextPluginStatus() === "unavailable") {
      mapboxgl.setRTLTextPlugin(
        "https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.3.0/mapbox-gl-rtl-text.js",
        null,
        true
      );
    }

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v12",
      center: coordinates || [9.555505, 34.689183],
      zoom: coordinates ? 15 : 4,
      language: language,
      interactive: false,
    });

    // Add marker if coordinates are provided
    if (coordinates) {
      new mapboxgl.Marker()
        .setLngLat([coordinates[0], coordinates[1]])
        .addTo(map);
    }

    // Clean up the map on unmount
    return () => {
      map.remove();
    };
  }, [coordinates, language]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setUserCoords([position.coords.longitude, position.coords.latitude]);
      });
    }
  }, []);

  return (
    <div>
      <div className="map-container" ref={mapContainerRef} />
      {coordinates && (
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <a
            href={
              userCoords &&
              `https://www.google.com/maps/dir/?api=1&origin=${userCoords[1]},${userCoords[0]}&destination=${coordinates[1]},${coordinates[0]}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="btn-light">{t("directions")}</button>
          </a>
        </div>
      )}
    </div>
  );
};

export default MapLocation;
