import React from "react";

const DescriptionContenu = ({ t, siteData, coming_soon }) => {
  return (
    <div
      className="header-contenu"
      style={{ direction: t("lang") === "ar" ? "rtl" : "ltr" }}
    >
      <div>
        <h1
          className="title"
          style={{
            display: "inline",
          }}
        >
          {siteData.name}{" "}
          {coming_soon && (
                <span className="coming-soon">({t("coming_soon")})</span>
              )}
        </h1>
      </div>
      <br />
      <p className="description-contenu">{siteData.description}</p>
    </div>
  );
};

export default DescriptionContenu;
