import React from "react";
import phones from "../../assets/experiences-home.png";
import Grid from "@mui/material/Grid2";
import iosIcon from "../../assets/apple-icon.png";
import androidIcon from "../../assets/android-icon.png";
import windowsIcon from "../../assets/windows-icon.png";
import "./HomeComponentStyle.css";

const HomeComponent = ({ t }) => {
  return (
    <div className="_container">
      <Grid container className="content">
        <Grid size={{ xs: 12, sm: 12, md: 6 }} sx={{ padding: "1em" }}>
          <center>
            <div className="title-home">{t("platforms_header")}</div>
            <br />
            <div className="sub-title-home">{t("platforms_subheader")}</div>
            <br />
            <br />
            <div className="app-icons">
              <img className="platfrom-img" alt="ios" src={iosIcon} />

              <img className="platfrom-img" alt="android" src={androidIcon} />

              <img className="platfrom-img" alt="windows" src={windowsIcon} />
            </div>
          </center>
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 6 }}>
          <center>
            <img className="phones" alt="Phones" src={phones} />
          </center>
        </Grid>
      </Grid>
    </div>
  );
};

export default HomeComponent;
