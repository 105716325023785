import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import "./SliderHome.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const SliderHome = ({ data, t }) => {
  function convertToThumbnailUrl(url) {
    // Extract the directory, filename, and extension
    const parts = url.split("/");
    const filename = parts.pop(); // Get the last part (e.g., "carthage.jpg")
    const [name, ext] = filename.split("."); // Split into name and extension

    // Construct the new path
    const thumbnailPath = `${parts.join(
      "/"
    )}/thumbnails/${name}_thumbnail.${ext}`;

    return thumbnailPath;
  }

  return (
    <div className="slider-container">
      <Swiper
        spaceBetween={10}
        slidesPerView={2}
        breakpoints={{
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
        modules={[Navigation, Thumbs, Autoplay]}
        navigation
        thumbs={{ autoScrollOffset: 1 }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
      >
        {data
          ? data.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  className="slide-content"
                  to={`/site/${item.link}/${t("lang")}`}
                >
                  <img
                    className="slide-image"
                    src={convertToThumbnailUrl(item.image)}
                    alt={item.name ? item.name : item.location_name}
                  />
                  <div className="label">
                    <div className="label-background"></div>
                    <div className="label-text">
                      {item.name ? item.name : item.location_name}
                    </div>
                  </div>
                </Link>
              </SwiperSlide>
            ))
          : ""}
      </Swiper>
    </div>
  );
};

export default SliderHome;
